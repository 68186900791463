<template>
  <div>
    <overlay v-if="hotelsOverlay"/>
    <v-progress-linear
      indeterminate
      color="primary"
      height="10"
      top
      :active="loaded"
    ></v-progress-linear>
    <v-snackbar v-model="snackbar" :color="color" top :timeout="60000">
      {{ text }} <router-link to="/hotels" style="color: blue; margin-left: 5px;"> Search again</router-link>
      <v-btn
        dark
        icon
        @click="snackbar = false"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container v-if="noData">
      <p class="body-1 font-weight-bold primary--text text-center">Sorry, We couldn't get any result <router-link to="/stays/results" style="color: deeppink; margin-left: 5px;"> Search again</router-link></p>
    </v-container>
    <v-container class="mt-5" v-if="data">
      <v-row class="px-3">
        <h3 class="font-weight-bold primary--text">{{hotel.HotelName}}</h3>
        <v-rating class="ml-3" dense :length="hotel.HotelStars" color="warning" readonly :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
        <v-spacer></v-spacer>
        <div v-if="hotel.tripAdivsorRating">
          <v-avatar color="indigo" size="36">
            <span class="white--text headline">{{hotel.tripAdivsorRating}}</span>
          </v-avatar>
        </div>
      </v-row>
      <v-row class="px-3">
        <v-icon small class="mt-0" color="primary">mdi-map-marker</v-icon>
        <p class="body-2 mb-0 mt-1">{{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}</p>
        <v-btn text color="primary" small @click="openMap(hotel.map)">
          Show on map
        </v-btn>
      </v-row>
      <v-row class="px-3">
        <v-icon small class="mr-1" color="primary">mdi-phone</v-icon>{{hotel.HotelPhone}}
        <v-icon small class="mr-1 ml-1" color="primary">mdi-fax</v-icon>{{hotel.HotelFax}}
      </v-row>
      <!-- <v-img :src="images[0]" class="mt-10" max-height="500" contain></v-img> -->
      <!-- <v-row v-if="length > 0" justify="center">
          <v-card flat tile width="60%">
              <v-window v-model="current">
                  <v-window-item
                  v-for="n in length"
                  :key="`card-${n}`"
                  :id="`card-${n}`"
                  >
                      <v-card
                      color="grey"
                      height="300"
                      >
                          <v-img :alt="hotel.HotelName" :src="images[n]" height="300" width="100%" class="mx-auto" @error="hideElement('card', n)"></v-img>
                      </v-card>
                  </v-window-item>
              </v-window>

              <v-card-actions class="justify-space-between">
                  <v-btn
                      text
                      @click="prev"
                  >
                      <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-item-group
                      v-model="current"
                      class="text-center"
                      mandatory
                  >
                      <v-item
                      v-for="n in length"
                      :key="`btn-${n}`"
                      v-slot:default="{ active, toggle }"
                      >
                          <v-btn
                          :input-value="active"
                          @click="toggle"
                          v-if="hotel.images[n]"
                          text
                          :id="`btn-${n}`"
                          >
                              <img :alt="hotel.HotelName" :src="hotel.images[n]" width="30" height="30" @error="hideElement('btn', n)"/>
                          </v-btn>
                      </v-item>
                  </v-item-group>
                  <v-btn
                      text
                      @click="next"
                  >
                      <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-row> -->
      <div class="mt-10">
        <Gallery :images="images" ></Gallery>
      </div>
      <h3 class="mt-10 primary--text">Most Popular Facilities</h3>
      <v-slide-group multiple show-arrows>
        <v-slide-item
          v-for="n in hotel.HotelFacilities.length"
          :key="n"
        >
          <v-chip
            class="ma-2"
            outlined
            color="primary"
          >
            {{hotel.HotelFacilities[n]}}
          </v-chip>
        </v-slide-item>
      </v-slide-group>

      <div v-if="typeof allrooms === 'string'">
        <v-alert
          border="top"
          color="error lighten-2"
          dark
        >
          {{allrooms}}
        </v-alert>
      </div>
      <div v-else>
        <v-card class="my-5">
          <v-row>
            <v-col cols="3" style="border-right: 0.1px solid #E0E0E0;">
              <v-list
                two-line
              >
                <v-subheader class="primary--text font-weight-bold">Select Room Type</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(room, i) in hotelRooms"
                    :key="i"
                  >
                    <v-list-item-content @click="getAvailableRooms(room.roomIndex, room.roomCombination)">
                      <v-list-item-title class="primary--text" v-html="room.roomName"></v-list-item-title>
                      <v-list-item-subtitle>${{room.rateSummary.totalFare}}, {{room.rateSummary.meal ? room.rateSummary.meal : 'No meal'}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="9">
              <v-list
                two-line
              >
                <v-subheader class="primary--text font-weight-bold">Available Rooms</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group v-model="room" color="secondry">
                  <v-list-item
                    v-for="(room, i) in availableRooms"
                    :key="i"
                    style="border-bottom: 0.1px solid #e0e0e0"
                  >
                    <input type="checkbox" class="check" :id="`checkbox-${i}`" @click="selectRoom(room, i)">
                    <v-list-item-content class="ml-2">
                      <div>
                        <div style="display: flex; justify-content: space-between;">
                          <p class="font-weight-bold body-1 primary--text">{{room.roomName}}</p>
                          <div>
                            <p class="font-weight-bold body-1 ml-2 primary--text">${{room.rateSummary.totalFare}} Total</p>
                            <details :value="i">
                              <summary class="primary--text" style="margin-bottom: 15px; font-size: 11px;">Show Day Rates</summary>
                              <p class="caption primary--text" v-for="(rate, i) in room.daysRate" :key="i">
                                ${{rate.baseFare}} on {{formatDate(rate.date)}}
                              </p>
                            </details>
                          </div>
                        </div>
                        <div>
                          <details :value="i+2">
                            <summary class="primary--text" style="margin-bottom: 15px;">Cancelation Policies</summary>
                            <p v-if="room.cancelPolicies.lastCancellationDeadLine">Cancellation DeadLine: {{room.cancelPolicies.lastCancellationDeadLine}}</p>
                            <p v-if="room.cancelPolicies.defaultPolicy">Hotel Policy: {{room.cancelPolicies.defaultPolicy}}</p>
                            <div v-if="room.cancelPolicies.policies && room.cancelPolicies.policies.length > 0">
                              <p v-for="(policy, i) in room.cancelPolicies.policies" :key="i">From {{policy.fromDate}} to {{policy.toDate}} cancelation charge {{policy.chargeType === 'Fixed' ? '$' : '%' }}{{policy.cancellationCharge}}</p>
                            </div>
                          </details>
                        </div>
                        <div v-html="room.description"></div>
                        <div>
                          <p ><span class="primary--text">Inclusions: </span>{{room.inclusion === "" ? 'No Inclusions' : room.inclusion}}</p>
                          <p ><span class="primary--text">Meals: </span>{{room.meal}}</p>
                          <p v-if="room.promotion !== '' && room.promotion"><span class="primary--text">Promotions: </span>{{room.promotion}}</p>
                          <details :value="i+1">
                            <summary class="primary--text" style="margin-bottom: 15px;">Show Amenities</summary>
                            <p class="primary--text">Amenities: {{room.amenities ? room.amenities : 'No Amenities'}}</p>
                          </details>
                          <div v-if="room.supplements">
                            <details :value="i+3">
                              <summary class="primary--text" style="margin-bottom: 15px;">Room supplements</summary>
                              <p class="body-2 font-weight-bold" v-for="sup in room.supplements" :key="sup.id">
                                {{sup.type}}: {{sup.name}}, {{sup.chargeType}}, {{sup.price}} {{sup.currencyCode}}
                                <span v-if="!sup.isMandatory">
                                                        <v-btn color="blue" text @click="supplements.push({sup: sup.id, room: room.roomIndex, accept: true})" small>Add Supplement</v-btn>
                                                      </span>
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="selectedRooms.length < $store.state.hotelSearch.guestsInfo.length" @click="reserve()">Reserve</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div class="primary--text" v-html="hotel.HotelDescription"></div>
      <div class="primary--text" v-html="hotel.nearByPlaces"></div>

      <v-dialog
        v-model="dialog"
        max-width="80%"
      >
        <gmap-map
          :center="center"
          :zoom="13"
          style="width:1000px;  height: 500px;"
          zoomControl
          class="mt-1"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="center=m.position"
            :clickable="true"
            :draggable="true"
          ></gmap-marker>
        </gmap-map>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { getHotel, headersNoAuth } from '../../links'
import overlay from '../../components/stays/overlay'
import Gallery from 'vue-cover-gallery'

export default {
  title: '-Room reservation',
  components: {
    overlay,
    Gallery
  },
  data () {
    return {
      loaded: true,
      snackbar: false,
      room: 1,
      color: '',
      text: '',
      noData: false,
      data: false,
      hotel: {},
      hotelRooms: [],
      dialog: false,
      dialog1: false,
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      current: 0,
      length: null,
      images: [],
      allrooms: [],
      availableRooms: [],
      selectedRooms: [],
      supplements: [],
      hotelsTimeOut: null,
      hotelsOverlay: false,
      now: null,
      prevTime: null
    }
  },
  watch: {
    data (newVal) {
      if (newVal) {
        setTimeout(() => {
          const element = document.getElementsByClassName('img-left')[0]
          element.classList.add('col-sm-12')
          element.classList.add('col-md-6')
        }, 100)
      }
    }
  },
  methods: {
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    next () {
      this.current = this.current + 1 === this.length
        ? 0
        : this.current + 1
    },
    prev () {
      this.current = this.current - 1 < 0
        ? this.length - 1
        : this.current - 1
    },
    hideElement (el, n) {
      if (el === 'btn') document.getElementById(`btn-${n}`).style.display = 'none'
      else {
        document.getElementById(`card-${n}`).style.display = 'none'
        this.current = this.current + 1 === this.length ? 0 : this.current + 1
      }
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    roomImageError (i) {
      document.getElementById(`room-image-${i}`).style.display = 'none'
    },
    getAvailableRooms (index, compination) {
      compination = [...new Set(compination)]
      this.availableRooms = []
      this.selectedRooms = []
      const checkboxes = document.getElementsByClassName('check')
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
      // this.availableRooms.push(this.allrooms[index])
      this.allrooms.forEach(room => {
        if (room.roomIndex === index) this.availableRooms.push(room)
        if (this.$store.state.hotelSearch.guestsInfo.length > 1 || this.$route.params.roomsNo > 1) {
          for (let i = 0; i < compination.length; i++) {
            if (room.roomIndex === compination[i] && room.roomIndex !== index) this.availableRooms.push(room)
          }
        }
      })
    },
    selectRoom (room, i) {
      if (this.selectedRooms.length < this.$store.state.hotelSearch.guestsInfo.length && document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms.push(room)
        document.getElementById(`checkbox-${i}`).checked = true
      } else if (this.selectedRooms.length <= this.$store.state.hotelSearch.guestsInfo.length && !document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms = this.selectedRooms.filter((val, index, arr) => {
          return val.roomIndex !== room.roomIndex
        })
        document.getElementById(`checkbox-${i}`).checked = false
      } else {
        document.getElementById(`checkbox-${i}`).checked = false
      }
    },
    reserve () {
      const params = {
        sessionId: this.$route.params.sessionId,
        hotelIndex: this.$route.params.hotelIndex,
        hotelCode: this.$route.params.hotelCode,
        rooms: this.selectedRooms,
        hotel: this.hotel,
        supplements: this.supplements
      }
      clearTimeout(this.hotelsTimeOut)
      this.hotelsOverlay = false
      const spentTime = Date.now() - this.now
      const timer = this.prevTime - spentTime
      localStorage.setItem('hotelsTimer', timer)
      this.$router.push({ name: 'hotelBook', params: params })
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.params) {
      this.now = Date.now()
      this.hotelsTimeOut = setTimeout(() => {
        this.hotelsOverlay = true
      }, 20 * 60 * 1000)
      // this.prevTime = localStorage.getItem('hotelsTimer')
      const params = this.$route.params
      this.$http.get(getHotel(params.sessionId, params.hotelIndex, params.hotelCode), { headers: headersNoAuth() }).then(res => {
        if (res.data.status === true) {
          this.loaded = false
          this.data = true
          this.hotel = res.data.data.Hotel
          this.length = res.data.data.Hotel.images.length
          res.data.data.Hotel.images.forEach(item => {
            this.images.push({
              title: '',
              description: '',
              href: item
            })
          })
          this.allrooms = res.data.data.HotelRooms
          let roomIndex = ''
          if (typeof this.allrooms !== 'string') {
            this.allrooms.forEach(room => {
              if (room.supplements) {
                room.supplements.forEach(sup => {
                  if (sup.isMandatory) this.supplements.push({ sup: sup.id, room: room.roomIndex, accept: true })
                })
              }
              if (room.roomIndex !== roomIndex) {
                roomIndex = room.roomIndex
                this.hotelRooms.push(room)
              } else roomIndex = room.roomIndex
            })
            this.getAvailableRooms(this.hotelRooms[0].roomIndex, this.hotelRooms[0].roomCombination ? this.hotelRooms[0].roomCombination : [])
          }
        } else {
          this.loaded = false
          // this.snackbar = true
          // this.color = 'error'
          // this.text = 'Sorry, We couldnot get any response'
          this.noData = true
        }
      }, () => {
        this.loaded = false
        // this.snackbar = true
        // this.color = 'error'
        // this.text = 'Sorry, We couldnot get any response'
        this.noData = true
      })
    } else {
      this.$router.push({ name: 'hotels' })
    }
  },
  beforeDestroy () {
    clearTimeout(this.hotelsTimeOut)
    this.hotelsOverlay = false
  }
}
</script>

<style>
.check {
  width: 19px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 1px;
}
.img-right, .img-left {
  border-color: white !important;
  cursor: pointer;
}
.img-left {
  height: 500px !important;
}
.img-right {
  height: 250px !important;
}
.inner-banner-outer {
  height: 500px !important;
  overflow: hidden !important;
}
.more-btn:hover {
  background-color: #2F80C2 !important;
}
</style>
